import React from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

function Step3({ ansDataH, setAnsDataH }) {
  /*console.log(ansDataH);*/

  return (
    <div className="c_data car">
      <div className="q_box" id="q_1">
        <label className="title__input">
          Building value:
          <input
            type="number"
            id="building_value"
            placeholder="AED"
            value={ansDataH.building_value}
            onChange={(event) => {
              setAnsDataH({
                ...ansDataH,
                building_value: event.target.value,
              });
            }}
          />
        </label>
        <a href="#q_2" title="Next">
          <ArrowCircleDownIcon />
        </a>
      </div>

      {/* CONTENT VALUE */}
      <div className="q_box" id="q_2">
        <label htmlFor="y_make" className="title__input">
          Content Value:
        </label>
        <input
          type="number"
          id="content_value"
          placeholder="AED"
          value={ansDataH.content_value}
          onChange={(event) => {
            setAnsDataH({
              ...ansDataH,
              content_value: event.target.value,
            });
          }}
        />
        <label>Do you have any items above 20K? </label>
        <div
          className="question-radio-container"
          onChange={(event) => {
            setAnsDataH({
              ...ansDataH,
              content_value_price: event.target.value,
            });
          }}
        >
          <label>
            <input
              type="radio"
              className="r_btn"
              name="content_value_price"
              value="Yes"
              checked={ansDataH.content_value_price === "Yes"}
              readOnly
            />
            <div className="label-text">
              <p>Yes</p>
              <span></span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              className="r_btn"
              name="content_value_price"
              value="No"
              checked={ansDataH.content_value_price === "No"}
              readOnly
            />
            <div className="label-text">
              <p>No</p>
              <span></span>
            </div>
          </label>
        </div>
        <a href="#q_3" title="Next">
          <ArrowCircleDownIcon />
        </a>
        <a href="#q_1" className="up" title="Back">
          <ArrowCircleUpIcon />
        </a>
      </div>

      {/* PERSONAL POSSESSION */}
      <div className="q_box" id="q_3">
        <label htmlFor="y_make" className="title__input">
          Personal Possessions Value:
        </label>
        <input
          type="number"
          id="content_value"
          placeholder="AED"
          value={ansDataH.p_p_value}
          onChange={(event) => {
            setAnsDataH({
              ...ansDataH,
              p_p_value: event.target.value,
            });
          }}
        />
        <label>Do you have any items above 20K? </label>
        <div
          className="question-radio-container"
          onChange={(event) => {
            setAnsDataH({
              ...ansDataH,
              p_p_value_price: event.target.value,
            });
          }}
        >
          <label>
            <input
              type="radio"
              className="r_btn"
              name="p_p_value_price"
              value="Yes"
              checked={ansDataH.p_p_value_price === "Yes"}
              readOnly
            />
            <div className="label-text">
              <p>Yes</p>
              <span></span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              className="r_btn"
              name="p_p_value_price"
              value="No"
              checked={ansDataH.p_p_value_price === "No"}
              readOnly
            />
            <div className="label-text">
              <p>No</p>
              <span></span>
            </div>
          </label>
        </div>
        <a href="#q_2" className="up" title="Back">
          <ArrowCircleUpIcon />
        </a>
      </div>
    </div>
  );
}

export default Step3;
