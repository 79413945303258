import React, { useEffect } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { company_list } from "../../data/CarInsuranceComp";
import Axios from "axios";

const Output = ({ ansDataH }) => {
  console.log(ansDataH);
  const c_ans_2 = ansDataH.coverage;

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  const customers_note = [
    "Online Purchase",
    "Claim Process",
    "Customer Service",
    "Overall Satisfaction",
  ];

  /* http://localhost:3001 https://miaapi.skwerzone.com*/
  var api__url = "https://miaapi.skwerzone.com";

  /* DATABASE AXIOS */
  const saveData = () => {
    /* http://localhost:3001 */
    Axios.post(`${api__url}/saveDataH`, {
      insurance_type: ansDataH.insurance_type,
      ownership: ansDataH.ownership,
      home_type: ansDataH.home_type,
      building_value: ansDataH.building_value,
      coverage: ansDataH.coverage,
      content_value: ansDataH.content_value,
      content_value_price: ansDataH.content_value_price,
      p_p_value: ansDataH.p_p_value,
      p_p_value_price: ansDataH.p_p_value_price,
      ins_like: ansDataH.ins_like,
      dateCreated: date,
      timeCreated: time,
    }).then(() => {
      console.log("HI Data Saved!");
    });
  };

  /* EXECUTE SAVING */
  useEffect(() => {
    saveData();
  });

  const ans_label = [
    {
      cat: "Insurance Type",
      data: ansDataH.insurance_type,
    },
    {
      cat: "Ownership",
      data: ansDataH.ownership,
    },
    {
      cat: "Home Type",
      data: ansDataH.home_type,
    },
  ];

  const ans_label2 = [
    {
      cat: "Building Value",
      data: ansDataH.building_value,
    },
    {
      cat: "Content Value",
      data: ansDataH.content_value,
    },
    {
      cat: "Do you have any items above 20K? ",
      data: ansDataH.content_value_price,
    },
    {
      cat: "Personal Possession Value",
      data: ansDataH.p_p_value,
    },
    {
      cat: "Do you have any items above 20K? ",
      data: ansDataH.p_p_value_price,
    },
  ];

  return (
    <>
      <div className="client__ans">
        <span
          className="car__move__output"
          // style={{ margin: `170px 0px 0px ${car__pos}` }}
        >
          <div>
            <img
              src="https://cdn-icons-png.flaticon.com/512/1018/1018675.png"
              alt="car__move"
            />
          </div>
        </span>
        <div className="ans__cols">
          <p>SUMMARY</p>
          <div className="summary__cols">
            <div className="ans__box">
              <div className="ans__item">
                <span className="ans__label">
                  <StarBorderIcon /> HOME INSURANCE
                </span>
                <span className="ans__data"></span>
              </div>
              {ans_label.map((item, index) => {
                return (
                  <div className="ans__item" key={index}>
                    <span className="ans__label">{item.cat}: </span>
                    <span className="ans__data">{item.data}</span>
                  </div>
                );
              })}
            </div>
            <div className="ans__box">
              {ans_label2.map((item, index) => {
                return (
                  <div className="ans__item" key={index}>
                    <span className="ans__label">{item.cat}: </span>
                    <span className="ans__data">{item.data}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <h2 className="question-text">
        Hi there &#128075;! This is what we recommend to you!
      </h2>
      <div className="c__output">
        <div className="cov__box">
          {/* ====== REGION DATA-USER INPUT ====== */}
          {c_ans_2.map((item, index) => {
            return (
              <div className="cov__item" key={index}>
                <StarBorderIcon />
                <span>{item}</span>
              </div>
            );
          })}
          <h5 className="ratings__div">RATINGS</h5>
          {/* ====== RATINGS CATEGORIES ====== */}
          {customers_note.map((item, index) => {
            return (
              <div className="cov__item" key={index}>
                <StarBorderIcon />
                <span>{item}</span>
              </div>
            );
          })}
        </div>
        {/* ====== LOOP COMPANY DATA USER SELECTED ====== */}
        {company_list.map((com__item, c__index) => {
          return (
            <>
              {com__item.name === ansDataH.ins_like ? (
                <div className="comp__box" key={c__index}>
                  <div className="comp__item">
                    {/* <h2>{com__item.company_name}</h2> */}
                    <div className="comp__logo">
                      <img src={com__item.logo} alt={com__item.name} />
                    </div>

                    {/* ====== COVERAGE DATA ====== */}
                    {c_ans_2.map((item2, index2) => {
                      return com__item.coverage.includes(item2) ? (
                        <i className="check__icon" key={index2}>
                          <CheckCircleIcon />
                        </i>
                      ) : (
                        <i className="x__icon">
                          <CancelIcon />
                        </i>
                      );
                    })}
                    {/* ====== RATINGS DATA ====== */}
                    <h5 className="ratings__div"> - - - - -</h5>
                    {com__item.ratings.map((item2, index2) => {
                      let stars = "";
                      for (let i = 0; i < item2; i++) {
                        stars = stars + "<span class='ratings'></span>";
                      }
                      for (let j = 0; j < 5 - item2; j++) {
                        stars = stars + "<span class='ratings__blank'></span>";
                      }
                      return (
                        <span
                          className="rating__box"
                          dangerouslySetInnerHTML={{ __html: stars }}
                        />
                      );
                    })}
                  </div>
                  <div className="comp__btn__box">
                    <a href={com__item.url}>{com__item.name}</a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
        {/* ====== LOOP COMPANIES DATA OTHERS ====== */}
        {company_list.map((com__item, c__index) => {
          return (
            <>
              {com__item.name !== ansDataH.ins_like ? (
                <div className="comp__box" key={c__index}>
                  <div className="comp__item">
                    {/* <h2>{com__item.company_name}</h2> */}
                    <div className="comp__logo">
                      <img src={com__item.logo} alt={com__item.name} />
                    </div>

                    {/* ====== COVERAGE DATA ====== */}
                    {c_ans_2.map((item2, index2) => {
                      return com__item.coverage.includes(item2) ? (
                        <i className="check__icon">
                          <CheckCircleIcon />
                        </i>
                      ) : (
                        <i className="x__icon">
                          <CancelIcon />
                        </i>
                      );
                    })}
                    {/* ====== RATINGS DATA ====== */}
                    <h5 className="ratings__div"> - - - - -</h5>
                    {com__item.ratings.map((item2, index2) => {
                      let stars = "";
                      for (let i = 0; i < item2; i++) {
                        stars = stars + "<span class='ratings'></span>";
                      }
                      for (let j = 0; j < 5 - item2; j++) {
                        stars = stars + "<span class='ratings__blank'></span>";
                      }
                      return (
                        <span
                          className="rating__box"
                          dangerouslySetInnerHTML={{ __html: stars }}
                        />
                      );
                    })}
                  </div>
                  <div className="comp__btn__box">
                    <a href={com__item.url}>{com__item.name}</a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Output;
