import React, { useState } from "react";
import { country_list } from "../../data/Country_list";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

function Step2({ ansDataC, setAnsDataC }) {
  // console.log(ansDataC);

  const [car__pos, setCar__pos] = useState("0px");

  return (
    <div className="c_data car">
      <div className="q_box" id="q_1">
        <label className="title__input">
          Which country was your license first issued in?{" "}
        </label>
        <select
          className="form-control"
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              country_lic: event.target.value,
            });
          }}
          value={ansDataC.country_lic}
        >
          <option hidden>-- SELECT COUNTRY --</option>
          {country_list.map((item, index) => {
            return (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            );
          })}
        </select>

        <a
          href="#q_2"
          onClick={() => {
            setCar__pos("290px");
          }}
        >
          <ArrowCircleDownIcon />
        </a>
        <span
          className="car__move"
          style={{ margin: `150px 0px 0px ${car__pos}` }}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/2769/2769618.png"
            alt="car__move"
          />
        </span>
      </div>

      <div className="q_box" id="q_2">
        <label htmlFor="y_make" className="title__input">
          How many years have of driving experience do you have?
        </label>
        <input
          type="number"
          id="y_make"
          min="0"
          max="99"
          value={ansDataC.y_exp}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              y_exp: event.target.value,
            });
          }}
        />
        <a
          href="#q_1"
          className="up"
          onClick={() => {
            setCar__pos("0px");
          }}
        >
          <ArrowCircleUpIcon />
        </a>
      </div>
    </div>
  );
}

export default Step2;
