import React, { useRef } from "react";
import { country_list } from "../../data/Country_list";

function Step4({ ansDataC, setAnsDataC }) {
  /* Validations */
  function isValidEmail(email) {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(email);
  }

  var mobile = "";

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength === 9) {
      mobile = `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(
        2,
        5
      )}-${phoneNumber.slice(5)}`;
      return mobile;
    }
  }

  const ref = useRef();

  return (
    <div className="c_data car_cov step7">
      <div>
        <span className="req__title__input">*</span>
        <input
          type="text"
          className=""
          placeholder="FIRST NAME"
          name="f_name"
          id="f_name"
          required
          value={ansDataC.f_name}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              f_name: event.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <input
          type="text"
          className=""
          placeholder="LAST NAME"
          name="l_name"
          id="l_name"
          required
          value={ansDataC.l_name}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              l_name: event.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <input
          type="email"
          className={
            ansDataC.email === ""
              ? ""
              : !isValidEmail(ansDataC.email)
              ? "error_box"
              : ""
          }
          placeholder="E-MAIL"
          name=""
          id="email"
          required
          value={ansDataC.email}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              email: event.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <label className="c_no_lbl">
          <img
            src="https://cdn-icons-png.flaticon.com/512/323/323301.png"
            alt="uae"
          />{" "}
          +971
        </label>
        <input
          type="text"
          className="c_no_input"
          placeholder=""
          name="l_name"
          id="l_name"
          required
          value={formatPhoneNumber(ansDataC.mobile)}
          maxLength="11"
          onChange={(e) => {
            setAnsDataC({
              ...ansDataC,
              mobile: e.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <input
          type="text"
          ref={ref}
          placeholder="DATE OF BIRTH"
          className=""
          name="dob"
          id="dob"
          required
          value={ansDataC.dob}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              dob: event.target.value,
            });
          }}
          onFocus={() => (ref.current.type = "date")}
          onBlur={() => (ref.current.type = "date")}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <select
          className="form-control"
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              nationality: event.target.value,
            });
          }}
          value={ansDataC.nationality}
        >
          <option hidden="hidden">-- SELECT NATIONALITY --</option>
          {country_list.map((item, index) => {
            return (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default Step4;
