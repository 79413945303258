import React from "react";

function Step2({ ansData, setAnsData }) {
  // console.log(ansData);

  return (
    <div className="question-radio-wrapper step__3">
      <div
        className="question-radio-container"
        onChange={(event) => {
          setAnsData({
            ...ansData,
            region: event.target.value,
          });
        }}
      >
        <label>
          <input
            type="radio"
            className="r_btn"
            name="region"
            value="Worldwide (excl. US & Canada)"
            checked={ansData.region === "Worldwide (excl. US & Canada)"}
            readOnly
          />
          <div className="label-text">
            <p>Worldwide (excl. US & Canada)</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="region"
            value="Worldwide (incl. US & Canada)"
            checked={ansData.region === "Worldwide (incl. US & Canada)"}
            readOnly
          />
          <div className="label-text">
            <p>Worldwide (incl. US & Canada)</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="region"
            value="United Arab Emirates"
            checked={ansData.region === "United Arab Emirates"}
            readOnly
          />
          <div className="label-text">
            <p>United Arab Emirates</p>
            <span></span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default Step2;
