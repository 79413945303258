import React, { useEffect } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { company_list } from "../../data/CarInsuranceComp";
import Axios from "axios";

const Output = ({ ansDataC }) => {
  console.log(ansDataC);

  const c_ans_2 = ansDataC.coverage;

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  const customers_note = [
    "Online Purchase",
    "Claim Process",
    "Customer Service",
    "Overall Satisfaction",
  ];

  var api__url = "https://miaapi.skwerzone.com";

  /* DATABASE AXIOS */
  const saveData = () => {
    /* http://localhost:3001 https://api.skwergroup.com*/
    Axios.post(`${api__url}/saveDataC`, {
      insurance_type: ansDataC.insurance_type,
      reg_location: ansDataC.reg_location,
      y_make: ansDataC.y_make,
      reg_f_date: ansDataC.reg_f_date,
      c_brand: ansDataC.c_brand,
      c_model: ansDataC.c_model,
      reg_type: ansDataC.reg_type,
      country_lic: ansDataC.country_lic,
      y_exp: ansDataC.y_exp,
      cov_type: ansDataC.cov_type,
      coverage: ansDataC.coverage,
      f_name: ansDataC.f_name,
      l_name: ansDataC.l_name,
      email: ansDataC.email,
      mobile: ansDataC.mobile,
      dob: ansDataC.dob,
      nationality: ansDataC.nationality,
      ins_like: ansDataC.ins_like,
      dateCreated: date,
      timeCreated: time,
    }).then(() => {
      console.log("CI Data Saved!");
    });
  };

  /* EXECUTE SAVING */
  useEffect(() => {
    saveData();
  });

  const ans_label = [
    {
      cat: "Registration Location",
      data: ansDataC.reg_location,
    },
    {
      cat: "Date of First Registration",
      data: ansDataC.reg_f_date,
    },
    {
      cat: "Year of Make",
      data: ansDataC.y_make,
    },
    {
      cat: "Car Brand",
      data: ansDataC.c_brand,
    },
    {
      cat: "Car Model",
      data: ansDataC.c_model,
    },
  ];

  const ans_label2 = [
    {
      cat: "Registration Type",
      data: ansDataC.reg_type,
    },
    {
      cat: "Country Licensed",
      data: ansDataC.country_lic,
    },
    {
      cat: "Year/s of Experience",
      data: ansDataC.y_exp,
    },
    {
      cat: "Coverage Type",
      data: ansDataC.cov_type,
    },
    {
      cat: "Company Selected",
      data: ansDataC.ins_like,
    },
  ];

  const ans_label3 = [
    {
      cat: "Name",
      data: ansDataC.f_name + " " + ansDataC.l_name,
    },
    {
      cat: "Email",
      data: ansDataC.email,
    },
    {
      cat: "Date of Birth",
      data: ansDataC.dob,
    },
    {
      cat: "Nationality",
      data: ansDataC.nationality,
    },
  ];

  return (
    <>
      <div className="client__ans">
        <span
          className="car__move__output"
          // style={{ margin: `170px 0px 0px ${car__pos}` }}
        >
          <div>
            <img
              src="https://cdn-icons-png.flaticon.com/512/2769/2769618.png"
              alt="car__move"
            />
          </div>
        </span>
        <div className="ans__cols">
          <p>SUMMARY</p>
          <div className="summary__cols">
            <div className="ans__box">
              <div className="ans__item">
                <span className="ans__label">
                  <StarBorderIcon /> CAR INSURANCE
                </span>
                <span className="ans__data"></span>
              </div>
              {ans_label3.map((item, index) => {
                return (
                  <div className="ans__item" key={index}>
                    <span className="ans__label">{item.cat}: </span>
                    <span className="ans__data">{item.data}</span>
                  </div>
                );
              })}
            </div>
            <div className="ans__box">
              {ans_label.map((item, index) => {
                return (
                  <div className="ans__item" key={index}>
                    <span className="ans__label">{item.cat}: </span>
                    <span className="ans__data">{item.data}</span>
                  </div>
                );
              })}
            </div>
            <div className="ans__box">
              {ans_label2.map((item, index) => {
                return (
                  <div className="ans__item" key={index}>
                    <span className="ans__label">{item.cat}: </span>
                    <span className="ans__data">{item.data}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <h2 className="question-text">
        Hi {ansDataC.f_name}
        &#128075;! This is what we recommend to you.
      </h2>
      <div className="c__output">
        <div className="cov__box">
          {/* ====== REGION DATA-USER INPUT ====== */}
          {c_ans_2.map((item, index) => {
            return (
              <div className="cov__item" key={index}>
                <StarBorderIcon />
                <span>{item}</span>
              </div>
            );
          })}
          <h5 className="ratings__div">RATINGS</h5>
          {/* ====== RATINGS CATEGORIES ====== */}
          {customers_note.map((item, index) => {
            return (
              <div className="cov__item" key={index}>
                <StarBorderIcon />
                <span>{item}</span>
              </div>
            );
          })}
        </div>
        {/* ====== LOOP COMPANY DATA USER SELECTED ====== */}
        {company_list.map((com__item, c__index) => {
          return (
            <>
              {com__item.name === ansDataC.ins_like ? (
                <div className="comp__box" key={c__index}>
                  <div className="comp__item">
                    {/* <h2>{com__item.company_name}</h2> */}
                    <div className="comp__logo">
                      <img src={com__item.logo} alt={com__item.name} />
                    </div>

                    {/* ====== COVERAGE DATA ====== */}
                    {c_ans_2.map((item2, index2) => {
                      return com__item.coverage.includes(item2) ? (
                        <i className="check__icon">
                          <CheckCircleIcon />
                        </i>
                      ) : (
                        <i className="x__icon">
                          <CancelIcon />
                        </i>
                      );
                    })}
                    {/* ====== RATINGS DATA ====== */}
                    <h5 className="ratings__div"> - - - - -</h5>
                    {com__item.ratings.map((item2, index2) => {
                      let stars = "";
                      for (let i = 0; i < item2; i++) {
                        stars = stars + "<span class='ratings'></span>";
                      }
                      for (let j = 0; j < 5 - item2; j++) {
                        stars = stars + "<span class='ratings__blank'></span>";
                      }
                      return (
                        <span
                          className="rating__box"
                          dangerouslySetInnerHTML={{ __html: stars }}
                        />
                      );
                    })}
                  </div>
                  <div className="comp__btn__box">
                    <a href={com__item.url}>{com__item.name}</a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
        {/* ====== LOOP COMPANIES DATA OTHERS ====== */}
        {company_list.map((com__item, c__index) => {
          return (
            <>
              {com__item.name !== ansDataC.ins_like ? (
                <div className="comp__box" key={c__index}>
                  <div className="comp__item">
                    {/* <h2>{com__item.company_name}</h2> */}
                    <div className="comp__logo">
                      <img src={com__item.logo} alt={com__item.name} />
                    </div>

                    {/* ====== COVERAGE DATA ====== */}
                    {c_ans_2.map((item2, index2) => {
                      return com__item.coverage.includes(item2) ? (
                        <i className="check__icon">
                          <CheckCircleIcon />
                        </i>
                      ) : (
                        <i className="x__icon">
                          <CancelIcon />
                        </i>
                      );
                    })}
                    {/* ====== RATINGS DATA ====== */}
                    <h5 className="ratings__div"> - - - - -</h5>
                    {com__item.ratings.map((item2, index2) => {
                      let stars = "";
                      for (let i = 0; i < item2; i++) {
                        stars = stars + "<span class='ratings'></span>";
                      }
                      for (let j = 0; j < 5 - item2; j++) {
                        stars = stars + "<span class='ratings__blank'></span>";
                      }
                      return (
                        <span
                          className="rating__box"
                          dangerouslySetInnerHTML={{ __html: stars }}
                        />
                      );
                    })}
                  </div>
                  <div className="comp__btn__box">
                    <a href={com__item.url}>{com__item.name}</a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Output;
