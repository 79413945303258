import React, { useState } from "react";

function Step3({ ansDataC, setAnsDataC }) {
  // console.log(ansDataC);

  const c_options = [
    "Loss or Damage to Your Vehicle",
    "Personal Accident Cover for Driver",
    "Personal Accident Cover for Passengers",
    "Medical Expenses Due to an Accident",
    "Personal Belongings",
    "Breakdown Recovery",
    "Hire Car",
    "Agency Repairs",
    "GCC Cover",
    "Natural Perils (Storm/Flood)",
    "Third Party Bodily Injury",
    "Third Party Property Damage",
    "Off-road Cover",
    "Pickup and delivery (Claim Service)",
    "Oman extension",
    "Ambulance Services",
    "Personal Accident Cover Worldwide",
    "Theft",
    "Fire",
    "Windscreen Damage",
    "Total Loss",
    "Strikes, Riots, and Civil Commotion",
    "Personal Injury",
    "Replacement Locks",
  ];

  const c_options_default = [
    "Agency Repairs",
    "GCC Cover",
    "Pickup and delivery (Claim Service)",
    "Personal Accident Cover Worldwide",
    "Replacement Locks",
  ];

  const c_img_url = ["https://cdn-icons-png.flaticon.com/512/2769/2769591.png"];

  const c_img_url_colored = [
    "https://cdn-icons-png.flaticon.com/512/2769/2769618.png",
  ];

  const t_options = [
    "Personal Accident Cover for Driver",
    "Personal Accident Cover for Passengers",
    "Breakdown Recovery",
    "Third Party Bodily Injury",
    "Third Party Property Damage",
    "Oman extension",
    "Ambulance Services",
    "Personal Accident Cover Worldwide",
  ];

  const t_options_default = [
    "Personal Accident Cover Worldwide - Third Party",
    "Oman extension - Third Party",
  ];

  const t_img_url = ["https://cdn-icons-png.flaticon.com/512/6666/6666847.png"];

  const t_img_url_colored = [
    "https://cdn-icons-png.flaticon.com/512/6931/6931183.png",
  ];

  const [checked, setChecked] = useState(ansDataC.coverage);
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);

    setAnsDataC({
      ...ansDataC,
      coverage: updatedList,
    });
  };

  return (
    <div className="c_data car_cov">
      <div className="question-radio-container">
        <span className="title__input">COVERAGE TYPE</span>
        <label
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              cov_type: event.target.value,
              coverage: c_options_default,
            });
            setChecked(c_options_default);
          }}
        >
          <input
            type="radio"
            className="r_btn"
            name="reg_type"
            value="Comprehensive"
            checked={ansDataC.cov_type === "Comprehensive"}
            readOnly
          />
          <div className="label-text">
            <p>Comprehensive</p>
            <span></span>
          </div>
        </label>
        <div
          className={
            ansDataC.cov_type === "Comprehensive"
              ? "comprehensive_cov display_cov"
              : "comprehensive_cov"
          }
        >
          <h3>COVERAGE</h3>
          <div className="com_items">
            {c_options.map((item, index) => {
              return (
                <article className="grid question-radio-cov" key={item}>
                  <label
                    className={
                      c_options_default.includes(item)
                        ? "customImage cov__default"
                        : "customImage"
                    }
                    style={
                      ansDataC.coverage.includes(item)
                        ? { backgroundImage: `url(${c_img_url_colored[0]})` }
                        : { backgroundImage: `url(${c_img_url[0]})` }
                    }
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundImage = `url(${c_img_url_colored[0]})`)
                    }
                    onMouseOut={(e) =>
                      ansDataC.coverage.includes(item)
                        ? ""
                        : (e.currentTarget.style.backgroundImage = `url(${c_img_url[0]})`)
                    }
                  >
                    <span
                      className={
                        ansDataC.coverage.includes(item)
                          ? "blue__txt cov__txt"
                          : "cov__txt"
                      }
                    >
                      {item}
                    </span>

                    <div className="question-radio-container">
                      <input
                        value={item}
                        type="checkbox"
                        onChange={handleCheck}
                        checked={ansDataC.coverage.includes(item)}
                        disabled={c_options_default.includes(item)}
                      />

                      <div className="label-text-check">
                        <span></span>
                      </div>
                    </div>
                  </label>
                </article>
              );
            })}
          </div>
        </div>
        <label
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              cov_type: event.target.value,
              coverage: t_options_default,
            });
            setChecked(t_options_default);
          }}
        >
          <input
            type="radio"
            className="r_btn"
            name="reg_type"
            value="Third Party"
            checked={ansDataC.cov_type === "Third Party"}
            readOnly
          />
          <div className="label-text">
            <p>Third Party</p>
            <span></span>
          </div>
        </label>
        <div
          className={
            ansDataC.cov_type === "Third Party"
              ? "comprehensive_cov display_cov"
              : "comprehensive_cov"
          }
        >
          <h3>COVERAGE</h3>
          <div className="com_items">
            {t_options.map((item, index) => {
              return (
                <article className="grid question-radio-cov" key={item}>
                  <label
                    className={
                      t_options_default.includes(item + " - Third Party")
                        ? "customImage cov__default"
                        : "customImage"
                    }
                    style={
                      ansDataC.coverage.includes(item + " - Third Party")
                        ? { backgroundImage: `url(${t_img_url_colored[0]})` }
                        : { backgroundImage: `url(${t_img_url[0]})` }
                    }
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundImage = `url(${t_img_url_colored[0]})`)
                    }
                    onMouseOut={(e) =>
                      ansDataC.coverage.includes(item + " - Third Party")
                        ? ""
                        : (e.currentTarget.style.backgroundImage = `url(${t_img_url[0]})`)
                    }
                  >
                    <span
                      className={
                        ansDataC.coverage.includes(item + " - Third Party")
                          ? "blue__txt cov__txt"
                          : "cov__txt"
                      }
                    >
                      {item}
                    </span>

                    <div className="question-radio-container">
                      <input
                        value={item + " - Third Party"}
                        type="checkbox"
                        onChange={handleCheck}
                        checked={ansDataC.coverage.includes(
                          item + " - Third Party"
                        )}
                        disabled={t_options_default.includes(
                          item + " - Third Party"
                        )}
                      />
                      {/* <i className='fa fa-check'></i> */}
                      <div className="label-text-check">
                        <span></span>
                      </div>
                    </div>
                  </label>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step3;
