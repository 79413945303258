import React from "react";

function Step2({ ansDataH, setAnsDataH }) {
  /*console.log(ansDataH);*/

  return (
    <div className="question-radio-wrapper step__3">
      <div
        className="question-radio-container"
        onChange={(event) => {
          setAnsDataH({
            ...ansDataH,
            home_type: event.target.value,
          });
        }}
      >
        <label>
          <input
            type="radio"
            className="r_btn"
            name="home_type"
            value="Building Only"
            checked={ansDataH.home_type === "Building Only"}
            readOnly
          />
          <div className="label-text">
            <p>Building Only</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="home_type"
            value="Contents Only"
            checked={ansDataH.home_type === "Contents Only"}
            readOnly
          />
          <div className="label-text">
            <p>Contents Only</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="home_type"
            value="Building & Contents"
            checked={ansDataH.home_type === "Building & Contents"}
            readOnly
          />
          <div className="label-text">
            <p>Building & Contents</p>
            <span></span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default Step2;
