import React from "react";

import "./Step1.css";

const Step1 = ({ ansData, setAnsData }) => {
  const options = [
    "Car Insurance",
    "Travel Insurance",
    "Health Insurance",
    "Home Insurance",
    "Life Insurance",
    "Pet Insurance",
  ];
  const img_url = [
    "https://cdn-icons-png.flaticon.com/512/2211/2211425.png",
    "https://cdn-icons-png.flaticon.com/512/870/870092.png",
    "https://cdn-icons-png.flaticon.com/512/2382/2382443.png",
    "https://cdn-icons-png.flaticon.com/512/1018/1018573.png",
    "https://cdn-icons-png.flaticon.com/512/547/547562.png",
    "https://cdn-icons-png.flaticon.com/512/2437/2437791.png",
  ];
  const img_url_colored = [
    "https://cdn-icons-png.flaticon.com/512/2211/2211380.png",
    "https://cdn-icons-png.flaticon.com/512/870/870143.png",
    "https://cdn-icons-png.flaticon.com/512/2382/2382533.png",
    "https://cdn-icons-png.flaticon.com/512/1018/1018675.png",
    "https://cdn-icons-png.flaticon.com/512/547/547501.png",
    "https://cdn-icons-png.flaticon.com/512/2437/2437632.png",
  ];

  console.log("step1");
  return (
    <section
      className="question-radio-wrapper grid grid__6 step__1 full__width"
      onChange={(event) => {
        setAnsData({
          ...ansData,
          insurance_type: event.target.value,
        });
      }}
    >
      {options.map((item, index) => {
        return (
          <article
            className="grid question-radio-container travel__cont"
            key={item}
          >
            <label>
              <img
                src={img_url_colored[index]}
                alt=""
                className={
                  ansData.insurance_type === item
                    ? "selected_img colored_img"
                    : "colored_img"
                }
              />
              <img
                src={img_url[index]}
                alt=""
                className={ansData.insurance_type === item ? "opacity_0" : ""}
              />

              <p>{item}</p>
              <div className="question-radio-container">
                <input
                  type="radio"
                  className="r_btn"
                  name="account"
                  value={item}
                  checked={ansData.insurance_type === item}
                  readOnly
                />

                <div className="label-text">
                  <span></span>
                </div>
              </div>
            </label>
          </article>
        );
      })}
    </section>
  );
};

export default Step1;
