import React from "react";

function Step3({ ansData, setAnsData }) {
  return (
    <div className="question-radio-wrapper ">
      <div
        className="question-radio-container"
        onChange={(event) => {
          setAnsData({
            ...ansData,
            how_often: event.target.value,
          });
        }}
      >
        <label>
          <input
            type="radio"
            className="r_btn"
            name="how_often"
            value="Single"
            checked={ansData.how_often === "Single"}
            readOnly
          />
          <div className="label-text">
            <p>Single Trip</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="how_often"
            value="Multiple"
            checked={ansData.how_often === "Multiple"}
            readOnly
          />
          <div className="label-text">
            <p>Multiple Trips</p>
            <span></span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default Step3;
