import React from "react";

function Step6({ ansData, setAnsData }) {
  /* Validations */
  function isValidEmail(email) {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(email);
  }

  var mobile = "";

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength === 9) {
      mobile = `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(
        2,
        5
      )}-${phoneNumber.slice(5)}`;
      return mobile;
    }
  }

  return (
    <div className="c_data step7">
      <div>
        <span className="req__title__input">*</span>
        <input
          type="text"
          className=""
          placeholder="First Name"
          name="f_name"
          id="f_name"
          required
          value={ansData.f_name}
          onChange={(event) => {
            setAnsData({
              ...ansData,
              f_name: event.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <input
          type="text"
          className=""
          placeholder="Last Name"
          name="l_name"
          id="l_name"
          required
          value={ansData.l_name}
          onChange={(event) => {
            setAnsData({
              ...ansData,
              l_name: event.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <input
          type="email"
          className={
            ansData.email === ""
              ? ""
              : !isValidEmail(ansData.email)
              ? "error_box"
              : ""
          }
          placeholder="E-mail"
          name=""
          id="email"
          required
          value={ansData.email}
          onChange={(event) => {
            setAnsData({
              ...ansData,
              email: event.target.value,
            });
          }}
        />
      </div>
      <div>
        <span className="req__title__input">*</span>
        <label className="c_no_lbl">
          <img
            src="https://cdn-icons-png.flaticon.com/512/323/323301.png"
            alt="uae"
          />{" "}
          +971
        </label>
        <input
          type="text"
          className="c_no_input"
          placeholder=""
          name="l_name"
          id="l_name"
          required
          value={formatPhoneNumber(ansData.mobile)}
          maxLength="11"
          onChange={(e) => {
            setAnsData({
              ...ansData,
              mobile: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
}

export default Step6;
