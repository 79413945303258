import React from "react";
import { useState } from "react";
import Header from "../../components/Header/Header";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Output from "./Output";

import "./travel.css";

const Travel = () => {
  const [page, setPage] = useState(0);

  const answers_T = {
    insurance_type: "Travel Insurance",
    age: null,
    region: "",
    how_often: "",
    t_with: "",
    coverage: [],
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
  };

  const [ansData, setAnsData] = useState(answers_T);

  const questions = [
    "Hey There! What's your age?",
    "Am sure this trip will be amazing for you! Which regions do you need cover for?",
    "That’s Great! How often do you travel in a year?",
    "Thanks! Are you traveling?",
    "Nice! Please select coverage you would like to have.",
    "Almost Done! I’ll get you an awesome price in minutes. Ready to go?",
    "Hi " + ansData.f_name + "! This is what we recommend to you!",
  ];

  const BodyContent = [
    <Step1
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
    <Step2
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
    <Step3
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
    <Step4
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
    <Step5
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
    <Step6
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
    <Output
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
  ];

  return (
    <main>
      <Header />
      <div className="question-wrapper">
        <div className="question-container">
          <div className="title__box">
            <img
              src="https://cdn-icons-png.flaticon.com/512/870/870143.png"
              alt=""
            />
          </div>
          <h2 className="question-text">{questions[page]}</h2>

          {BodyContent[page]}

          <div
            className={page === 0 ? "arrow_back none" : "arrow_back block"}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          ></div>

          <div className="question-submit-wrapper">
            <button
              className=""
              disabled={
                (page === 0 && !ansData.insurance_type) ||
                (page === 0 && !ansData.age) ||
                (page === 1 && !ansData.region) ||
                (page === 2 && !ansData.how_often) ||
                (page === 3 && !ansData.t_with) ||
                (page === 4 && ansData.coverage.length === 0) ||
                (page === 5 &&
                  (!ansData.f_name ||
                    !ansData.l_name ||
                    !ansData.email ||
                    !ansData.mobile))
              }
              onClick={() => {
                if (page === questions.length - 1) {
                  /* LAST PAGE */
                  setPage(0);
                  setAnsData(answers_T);
                } else {
                  setPage((currPage) => currPage + 1);
                }
              }}
            >
              {page === 7
                ? "ASK"
                : page === questions.length - 1
                ? "START AGAIN"
                : "NEXT"}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Travel;
