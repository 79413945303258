import React from "react";

function Step1({ ansDataH, setAnsDataH }) {
  /*console.log(ansDataH);*/

  return (
    <div className="question-radio-wrapper step__3">
      <div
        className="question-radio-container"
        onChange={(event) => {
          setAnsDataH({
            ...ansDataH,
            ownership: event.target.value,
          });
        }}
      >
        <label>
          <input
            type="radio"
            className="r_btn"
            name="ownership"
            value="Landlord living inside home"
            checked={ansDataH.ownership === "Landlord living inside home"}
            readOnly
          />
          <div className="label-text">
            <p>Landlord living inside home</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="ownership"
            value="Landlord with property on rent"
            checked={ansDataH.ownership === "Landlord with property on rent"}
            readOnly
          />
          <div className="label-text">
            <p>Landlord with property on rent</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="ownership"
            value="I rent my home "
            checked={ansDataH.ownership === "I rent my home "}
            readOnly
          />
          <div className="label-text">
            <p>I rent my home </p>
            <span></span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default Step1;
