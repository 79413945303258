import React from "react";

function Step1({ ansData, setAnsData }) {
  // console.log(ansData);
  return (
    <div className="c_data">
      <div>
        <span className="req__title__input">*</span>
        <input
          type="number"
          className="age"
          placeholder="AGE"
          name="age"
          id="age"
          min="0"
          max="100"
          value={ansData.age}
          onChange={(event) => {
            setAnsData({
              ...ansData,
              age: event.target.value,
            });
          }}
        />
      </div>
    </div>
  );
}

export default Step1;
