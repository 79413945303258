import React, { useState } from "react";

const Step5 = ({ ansData, setAnsData }) => {
  const options = [
    "Medical emergencies or medical evacuations",
    "Covid-19",
    "Baggage Loss or Damage",
    "Trip cancellation, curtailment (cutting short) or delays",
    "Personal Belongings Loss/ theft of and/or damage",
    "Sports Activity Cover",
    "Terrorism Or War Cover",
    "24/7 Worldwide Assistance",
  ];

  const img_url = [
    "https://cdn-icons-png.flaticon.com/512/3063/3063181.png",
    "https://cdn-icons-png.flaticon.com/512/2913/2913465.png",
    "https://cdn-icons-png.flaticon.com/512/2995/2995991.png",
    "https://cdn-icons-png.flaticon.com/512/2948/2948113.png",
    "https://cdn-icons-png.flaticon.com/512/584/584067.png",
    "https://cdn-icons-png.flaticon.com/512/2315/2315531.png",
    "https://cdn-icons-png.flaticon.com/512/2302/2302821.png",
    "https://cdn-icons-png.flaticon.com/512/2331/2331123.png",
  ];

  const img_url_colored = [
    "https://cdn-icons-png.flaticon.com/512/3063/3063206.png",
    "https://cdn-icons-png.flaticon.com/512/2913/2913584.png",
    "https://cdn-icons-png.flaticon.com/512/2996/2996126.png",
    "https://cdn-icons-png.flaticon.com/512/2948/2948147.png",
    "https://cdn-icons-png.flaticon.com/512/584/584026.png",
    "https://cdn-icons-png.flaticon.com/512/2315/2315556.png",
    "https://cdn-icons-png.flaticon.com/512/2302/2302747.png",
    "https://cdn-icons-png.flaticon.com/512/2420/2420449.png",
  ];

  const [checked, setChecked] = useState(ansData.coverage);
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);

    setAnsData({
      ...ansData,
      coverage: updatedList,
    });
  };

  const [select, setSelect] = useState(false);

  const selectAll = (event) => {
    setSelect(!select);

    if (select === false) {
      setChecked(options);
      setAnsData({
        ...ansData,
        coverage: options,
      });
    } else {
      setChecked([]);
      setAnsData({
        ...ansData,
        coverage: [],
      });
    }
  };

  return (
    <section className="question-radio-wrapper grid grid__5 step__6">
      {options.map((item, index) => {
        return (
          <article
            className="grid question-radio-container travel__cont"
            key={item}
          >
            <label>
              <img
                src={img_url_colored[index]}
                alt=""
                className={
                  ansData.coverage.includes(item)
                    ? "selected_img colored_img"
                    : "colored_img"
                }
              />
              <img
                src={img_url[index]}
                alt=""
                className={ansData.coverage.includes(item) ? "opacity_0" : ""}
              />

              {/* <p><span className={isChecked(item)}>{item}</span></p> */}
              <p>
                <span>{item}</span>
              </p>
              <div className="question-radio-container">
                <input
                  value={item}
                  type="checkbox"
                  onChange={handleCheck}
                  checked={ansData.coverage.includes(item)}
                />
                {/* <i className='fa fa-check'></i> */}
                <div className="label-text-check">
                  <span></span>
                </div>
              </div>
            </label>
          </article>
        );
      })}

      {/* <article className='select__all' onClick={selectAll}>
              <p className='grid'>
                <img src="https://cdn-icons-png.flaticon.com/512/6791/6791242.png" alt="" /> <span>{select === false ? "SELECT ALL" : "UNSELECT ALL"}</span>
              </p>
            </article> */}
      <article
        className="grid question-radio-container select__all"
        onClick={selectAll}
      >
        <label>
          {/* <img src="https://cdn-icons-png.flaticon.com/512/6791/6791242.png" alt="" class="colored_img" /> */}
          <img
            src="https://cdn-icons-png.flaticon.com/512/6791/6791180.png"
            alt=""
            className=""
          />
          <p>
            <span>{select === false ? "SELECT ALL" : "UNSELECT ALL"}</span>
          </p>
        </label>
      </article>
    </section>
  );
};

export default Step5;
