import './App.css';
import Initial from './components/Initial/Initial';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CarInsurance from './scenes/car';
import TravelInsurance from './scenes/travel';
import HomeInsurance from './scenes/home';

function App() {
  return (
    <BrowserRouter basename='/'>
      <Routes>
      <Route path="/" element={<Initial />} />
      <Route path="/travel" element={<TravelInsurance />} />
      <Route path="/car" element={<CarInsurance />} />
      <Route path="/home" element={<HomeInsurance />} />
    </Routes> 
    </BrowserRouter>
    
  );
}

export default App;
