import React, { useState } from "react";
import { emirates_list } from "../../data/EmiratesList";
import { car_make_model } from "../../data/CarModel";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

function Step1({ ansDataC, setAnsDataC }) {
  // console.log(ansDataC);

  const c_type = car_make_model;

  var model_type = [];

  c_type.map((item) => {
    if (item.brand === ansDataC.c_brand) {
      model_type = item.models;
    }
    return "";
  });

  const [car__pos, setCar__pos] = useState("0px");

  return (
    <div className="c_data car">
      <div className="q_box" id="q_1">
        <label className="title__input">Registration Location:</label>
        <select
          className="form-control"
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              reg_location: event.target.value,
            });
          }}
          value={ansDataC.reg_location}
        >
          <option hidden>-- SELECT LOCATION --</option>
          {emirates_list.map((item, index) => {
            return (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            );
          })}
        </select>
        <a
          href="#q_2"
          title="Next"
          onClick={() => {
            setCar__pos("75px");
          }}
        >
          <ArrowCircleDownIcon />
        </a>
        <span
          className="car__move"
          style={{ margin: `170px 0px 0px ${car__pos}` }}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/2769/2769618.png"
            alt="car__move"
          />
        </span>
      </div>

      <div className="q_box" id="q_2">
        <label htmlFor="y_make" className="title__input">
          Year Of Make:
        </label>
        <input
          type="number"
          id="y_make"
          placeholder="YYYY"
          min="1999"
          max="2023"
          value={ansDataC.y_make}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              y_make: event.target.value,
            });
          }}
        />
        <a
          href="#q_3"
          title="Next"
          onClick={() => {
            setCar__pos("140px");
          }}
        >
          <ArrowCircleDownIcon />
        </a>
        <a
          href="#q_1"
          className="up"
          title="Back"
          onClick={() => {
            setCar__pos("0px");
          }}
        >
          <ArrowCircleUpIcon />
        </a>
      </div>

      <div className="q_box" id="q_3">
        <label htmlFor="y_make" className="title__input">
          First Registraton Date:
        </label>
        <input
          type="date"
          id="reg_f_date"
          /*min="1999"
          max="2023"*/
          value={ansDataC.reg_f_date}
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              reg_f_date: event.target.value,
            });
          }}
        />
        <a
          href="#q_4"
          title="Next"
          onClick={() => {
            setCar__pos("225px");
          }}
        >
          <ArrowCircleDownIcon />
        </a>
        <a
          href="#q_2"
          className="up"
          title="Back"
          onClick={() => {
            setCar__pos("70px");
          }}
        >
          <ArrowCircleUpIcon />
        </a>
      </div>
      <div className="q_box" id="q_4">
        <label className="title__input">Car Brand:</label>
        <select
          className="form-control"
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              c_brand: event.target.value,
            });
          }}
          value={ansDataC.c_brand}
        >
          <option hidden>-- SELECT CAR BRAND --</option>
          {car_make_model.map((item, index) => {
            return (
              <option key={index} value={item.brand}>
                {item.brand}
              </option>
            );
          })}
        </select>

        <label className="title__input">Car Model:</label>
        <select
          name="type_1"
          className="form-control"
          onChange={(event) =>
            setAnsDataC({
              ...ansDataC,
              c_model: event.target.value,
            })
          }
          value={ansDataC.c_model}
        >
          <option hidden>-- SELECT CAR MODEL --</option>

          {model_type.map((item) => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </select>

        <a
          href="#q_5"
          title="Next"
          onClick={() => {
            setCar__pos("295px");
          }}
        >
          <ArrowCircleDownIcon />
        </a>
        <a
          href="#q_3"
          className="up"
          title="Back"
          onClick={() => {
            setCar__pos("140px");
          }}
        >
          <ArrowCircleUpIcon />
        </a>
      </div>
      <div className="q_box" id="q_5">
        <label className="title__input">Registration Type:</label>
        <div
          className="question-radio-container"
          onChange={(event) => {
            setAnsDataC({
              ...ansDataC,
              reg_type: event.target.value,
            });
          }}
        >
          <label>
            <input
              type="radio"
              className="r_btn"
              name="reg_type"
              value="New Vehicle"
              checked={ansDataC.reg_type === "New Vehicle"}
              readOnly
            />
            <div className="label-text">
              <p>New Vehicle</p>
              <span></span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              className="r_btn"
              name="reg_type"
              value="Renewal"
              checked={ansDataC.reg_type === "Renewal"}
              readOnly
            />
            <div className="label-text">
              <p>Renewal</p>
              <span></span>
            </div>
          </label>
          <label>
            <input
              type="radio"
              className="r_btn"
              name="reg_type"
              value="Change Of Ownership"
              checked={ansDataC.reg_type === "Change Of Ownership"}
              readOnly
            />
            <div className="label-text">
              <p>Change Of Ownership</p>
              <span></span>
            </div>
          </label>
        </div>
        <a
          href="#q_4"
          className="up"
          title="Back"
          onClick={() => {
            setCar__pos("225px");
          }}
        >
          <ArrowCircleUpIcon />
        </a>
      </div>
    </div>
  );
}

export default Step1;
