import RSA_logo from "../assets/RSA.png";

export const company_list = [
  {
    name: "RSA",
    code: "RSA",
    url: "https://www.rsadirect.ae/",
    logo: RSA_logo,
    coverage: [
      "Loss or Damage to Your Vehicle",
      "Personal Accident Cover for Driver",
      "Personal Accident Cover for Passengers",
      "Medical Expenses Due to an Accident",
      "Personal Belongings",
      "Breakdown Recovery",
      "Hire Car",
      "Agency Repairs",
      "GCC Cover",
      "Natural Perils (Storm/Flood)",
      "Third Party Bodily Injury",
      "Third Party Property Damage",
      "Off-road Cover",
      "Pickup and delivery (Claim Service)",
      "Oman extension",
      "Ambulance Services",
      "Personal Accident Cover Worldwide",
      "Theft",
      "Fire",
      "Windscreen Damage",
      "Total Loss",
      "Strikes, Riots, and Civil Commotion",
      "Personal Injury",
      "Replacement Locks",
    ],
    ratings: [5, 4, 4, 4],
  },
  {
    name: "GIG Gulf",
    code: "GIG",
    url: "https://www.giggulf.ae/en/",
    logo: "https://www.giggulf.ae/image/layout_set_logo?img_id=11683737&t=1674761515466",
    coverage: [
      "Loss or Damage to Your Vehicle",
      "Personal Accident Cover for Driver",
      "Personal Accident Cover for Passengers",
      "Medical Expenses Due to an Accident",
      "Personal Belongings",
      "Breakdown Recovery",
      "Hire Car",
      "Agency Repairs",
      "GCC Cover",
      "Natural Perils (Storm/Flood)",
      "Third Party Bodily Injury",
      "Third Party Property Damage",
      "Off-road Cover",
      "Pickup and delivery (Claim Service)",
      "Oman extension",
      "Ambulance Services",
      "Personal Accident Cover Worldwide",
      "Theft",
      "Fire",
      "Windscreen Damage",
      "Total Loss",
      "Strikes, Riots, and Civil Commotion",
      "Personal Injury",
      "Replacement Locks",
    ],
    ratings: [5, 4, 4, 4],
  },
  {
    name: "Sukoon Insurance",
    code: "Sukoon",
    url: "https://www.sukoon.com/",
    logo: "https://www.sukoon.com/-/media/oic/oic-media/logo/sukoon-logo.png?h=100&iar=0&mh=100&w=225&hash=0278DE0E8E51C944C8CA9567696BC378",
    coverage: [
      "Loss or Damage to Your Vehicle",
      "Personal Accident Cover for Driver",
      "Personal Accident Cover for Passengers",
      "Medical Expenses Due to an Accident",
      "Personal Belongings",
      "Breakdown Recovery",
      "Hire Car",
      "Agency Repairs",
      "GCC Cover",
      "Natural Perils (Storm/Flood)",
      "Third Party Bodily Injury",
      "Third Party Property Damage",
      "Off-road Cover",
      "Pickup and delivery (Claim Service)",
      "Oman extension",
      "Ambulance Services",
      "Personal Accident Cover Worldwide",
      "Theft",
      "Fire",
      "Windscreen Damage",
      "Total Loss",
      "Strikes, Riots, and Civil Commotion",
      "Personal Injury",
      "Replacement Locks",
    ],
    ratings: [5, 4, 4, 4],
  },
  {
    name: "Watania",
    code: "Watania",
    url: "https://www.watania.ae/",
    logo: "https://www.watania.ae/wp-content/uploads/logo-watania-1.png",
    coverage: [
      "Agency Repairs",
      "GCC Cover",
      "Personal Accident Cover Worldwide",
      "Replacement Locks",
    ],
    ratings: [5, 4, 4, 4],
  },
  {
    name: "Salama",
    code: "Salama",
    url: "https://salama.ae/",
    logo: "https://salama.ae/wp-content/uploads/2020/10/Salama-Logo.svg",
    coverage: [
      "Agency Repairs",
      "GCC Cover",
      "Personal Accident Cover Worldwide",
    ],
    ratings: [5, 4, 4, 4],
  },
  {
    name: "Adnic",
    code: "Adnic",
    url: "https://adnic.ae/",
    logo: "https://adnic.ae/image/layout_set_logo?img_id=62129&t=1673339641058",
    coverage: ["Agency Repairs", "GCC Cover", "Replacement Locks"],
    ratings: [5, 4, 4, 4],
  },
];
