import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/mia__logo__dark.png";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const [menuStat, setMenuStat] = useState(false);
  return (
    <div className="mia__header">
      <header>
        <div className="header_txt">
          {/* Insurance Ask</a> */}
          <a href="https://mia.skwerzone.com/">
            <img src={Logo} alt="logo" />
          </a>
        </div>
      </header>
      <i
        className="mobile__menu"
        onClick={() => {
          setMenuStat(!menuStat);
        }}
      >
        <MenuIcon />
      </i>
      <nav
        className={menuStat === true ? "top-nav menu__show" : "top-nav"}
        id="nav"
      >
        <ul>
          <li>
            <a href="#nav" className="href">
              Individual Insurance
            </a>
          </li>
          <li>
            <a href="#nav" className="href">
              Business Insurance
            </a>
          </li>
          <li>
            <a href="#nav" className="href">
              About Us
            </a>
          </li>
          <li>
            <a href="#nav" className="href">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
      {/* <div className="mobile-nav">
        
        <ul>
          <li>
            <a href="#nav" className="href">
              Individual Insurance
            </a>
          </li>
          <li>
            <a href="#nav" className="href">
              Business Insurance
            </a>
          </li>
          <li>
            <a href="#nav" className="href">
              About Us
            </a>
          </li>
          <li>
            <a href="#nav" className="href">
              Contact Us
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default Header;
