import React from "react";
import { useState } from "react";
import Header from "../../components/Header/Header";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Output from "./Output";

import "./home.css";

const Home = () => {
  const [page, setPage] = useState(0);

  const answers_H = {
    insurance_type: "Home Insurance",
    ownership: "",
    home_type: "",
    building_value: "",
    coverage: ["Home Coverage 1", "Coverage 2"],
    content_value: "",
    content_value_price: "",
    p_p_value: "",
    p_p_value_price: "",
    ins_like: "",
  };

  const [ansDataH, setAnsDataH] = useState(answers_H);

  const questions = [
    "About You Home",
    "Nice! Please select coverage you would like to have.?",
    "How much cover do you need?",
    "Insurers You Might Like!",
    "",
  ];

  const BodyContent = [
    <Step1
      page={page}
      setPage={setPage}
      ansDataH={ansDataH}
      setAnsDataH={setAnsDataH}
    />,
    <Step2
      page={page}
      setPage={setPage}
      ansDataH={ansDataH}
      setAnsDataH={setAnsDataH}
    />,
    <Step3
      page={page}
      setPage={setPage}
      ansDataH={ansDataH}
      setAnsDataH={setAnsDataH}
    />,
    <Step4
      page={page}
      setPage={setPage}
      ansDataH={ansDataH}
      setAnsDataH={setAnsDataH}
    />,
    <Output
      page={page}
      setPage={setPage}
      ansDataH={ansDataH}
      setAnsDataH={setAnsDataH}
    />,
  ];

  return (
    <main>
      <Header />
      <div className="question-wrapper">
        <div className="question-container">
          <div
            className={page === questions.length - 1 ? "none" : "title__box"}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/1018/1018675.png"
              alt=""
            />
          </div>
          <h2 className="question-text">{questions[page]}</h2>

          {BodyContent[page]}

          <div
            className={page === 0 ? "arrow_back none" : "arrow_back block"}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          ></div>

          <div className="question-submit-wrapper">
            <button
              className=""
              disabled={
                (page === 0 && !ansDataH.ownership) ||
                (page === 1 && !ansDataH.home_type) ||
                (page === 2 &&
                  (!ansDataH.building_value ||
                    !ansDataH.content_value ||
                    !ansDataH.content_value_price ||
                    !ansDataH.p_p_value ||
                    !ansDataH.p_p_value_price))
              }
              onClick={() => {
                if (page === questions.length - 1) {
                  /* LAST PAGE */
                  setPage(0);
                  setAnsDataH(answers_H);
                } else {
                  setPage((currPage) => currPage + 1);
                }
              }}
            >
              {page === 7
                ? "ASK"
                : page === questions.length - 1
                ? "START AGAIN"
                : "NEXT"}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
