import React from "react";
import { useState } from "react";
import Header from "../../components/Header/Header";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Output from "./Output";
import "./car.css";

const Car = () => {
  const [page, setPage] = useState(0);

  const answers_C = {
    insurance_type: "Car Insurance",
    reg_location: "",
    y_make: 2023,
    reg_f_date: "",
    c_brand: "",
    c_model: "",
    reg_type: "",
    country_lic: "",
    y_exp: "",
    cov_type: "",
    coverage: [],
    f_name: "",
    l_name: "",
    email: "",
    mobile: "",
    dob: "",
    nationality: "",
    ins_like: "",
  };

  const [ansDataC, setAnsDataC] = useState(answers_C);

  const questions = [
    "About Your Car?",
    "About Your Driving?",
    "Nice! Please select coverage you would like to have.?",
    "About You?",
    "Insurers You Might Like!",
    "",
  ];

  const BodyContent = [
    <Step1
      page={page}
      setPage={setPage}
      ansDataC={ansDataC}
      setAnsDataC={setAnsDataC}
    />,
    <Step2
      page={page}
      setPage={setPage}
      ansDataC={ansDataC}
      setAnsDataC={setAnsDataC}
    />,
    <Step3
      page={page}
      setPage={setPage}
      ansDataC={ansDataC}
      setAnsDataC={setAnsDataC}
    />,
    <Step4
      page={page}
      setPage={setPage}
      ansDataC={ansDataC}
      setAnsDataC={setAnsDataC}
    />,
    <Step5
      page={page}
      setPage={setPage}
      ansDataC={ansDataC}
      setAnsDataC={setAnsDataC}
    />,
    <Output
      page={page}
      setPage={setPage}
      ansDataC={ansDataC}
      setAnsDataC={setAnsDataC}
    />,
  ];

  return (
    <main>
      <Header />
      {/* <div className="title__box">
        <img
          src="https://cdn-icons-png.flaticon.com/512/2211/2211425.png"
          alt=""
        />
        <h1>Car Insurance</h1>
      </div> */}

      <div className="question-wrapper">
        <div className="question-container">
          <div
            className={page === questions.length - 1 ? "none" : "title__box"}
          >
            <img
              className="bounce"
              src="https://cdn-icons-png.flaticon.com/512/2211/2211380.png"
              alt=""
            />
          </div>
          <h2 className="question-text">{questions[page]}</h2>

          {BodyContent[page]}

          <div
            className={page === 0 ? "arrow_back none" : "arrow_back block"}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          ></div>

          <div className="question-submit-wrapper">
            <button
              className=""
              disabled={
                (page === 0 &&
                  (!ansDataC.reg_location ||
                    !ansDataC.y_make ||
                    !ansDataC.reg_f_date ||
                    !ansDataC.c_brand ||
                    !ansDataC.c_model ||
                    !ansDataC.reg_type)) ||
                (page === 1 && (!ansDataC.country_lic || !ansDataC.y_exp)) ||
                (page === 2 && !ansDataC.cov_type) ||
                (page === 2 && ansDataC.coverage.length === 0) ||
                (page === 3 &&
                  (!ansDataC.f_name ||
                    !ansDataC.l_name ||
                    !ansDataC.email ||
                    !ansDataC.mobile ||
                    !ansDataC.dob ||
                    !ansDataC.nationality)) ||
                (page === 4 && !ansDataC.ins_like)
              }
              onClick={() => {
                if (page === questions.length - 1) {
                  /* LAST PAGE */
                  setPage(0);
                  setAnsDataC(answers_C);
                } else {
                  setPage((currPage) => currPage + 1);
                }
              }}
            >
              {page === 7
                ? "ASK"
                : page === questions.length - 1
                ? "START AGAIN"
                : "NEXT"}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Car;
