export const emirates_list = [
  { name: "Abu Dhabi" },
  { name: "Dubai" },
  { name: "Sharjah" },
  { name: "Algeria" },
  { name: "Ajman" },
  { name: "Umm Al Quwain" },
  { name: "Ras Al Khaimah" },
  { name: "Fujairah" },
];
