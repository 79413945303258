import React from "react";

function Step4({ ansData, setAnsData }) {
  return (
    <div className="question-radio-wrapper ">
      <div
        className="question-radio-container"
        onChange={(event) => {
          setAnsData({
            ...ansData,
            t_with: event.target.value,
          });
        }}
      >
        <label>
          <input
            type="radio"
            className="r_btn"
            name="t_with"
            value="Individual"
            checked={ansData.t_with === "Individual"}
            readOnly
          />
          <div className="label-text">
            <p>Individual</p>
            <span></span>
          </div>
        </label>
        <label>
          <input
            type="radio"
            className="r_btn"
            name="t_with"
            value="With Family"
            checked={ansData.t_with === "With Family"}
            readOnly
          />
          <div className="label-text">
            <p>With Family</p>
            <span></span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default Step4;
