import React from "react";
import { company_list } from "../../data/CarInsuranceComp";

const Step4 = ({ ansDataH, setAnsDataH }) => {
  /* console.log(ansDataH); */

  return (
    <section className="output__box">
      <div className="carI__companies">
        {company_list.map((item, index) => {
          return (
            <div
              key={index}
              className={
                item.name === ansDataH.ins_like
                  ? "carI__box selected"
                  : "carI__box"
              }
              onClick={(event) => {
                setAnsDataH({
                  ...ansDataH,
                  ins_like: item.name,
                });
              }}
            >
              <img src={item.logo} alt={item.name} />
              <h4>{item.name}</h4>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Step4;
