import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Start from "../Start/Start";
import Step1 from "../Step1/Step1";
import Header from "../Header/Header";

import "./Initial.css";

import lottie from "lottie-web";

function Initial() {
  const [page, setPage] = useState(0);

  const answers = {
    insurance_type: "",
  };

  const [ansData, setAnsData] = useState(answers);

  const questions = [
    "Forget Everything You Know About Insurance",
    "Which insurance would you like?",
  ];

  const BodyContent = [
    <Start />,
    <Step1
      page={page}
      setPage={setPage}
      ansData={ansData}
      setAnsData={setAnsData}
    />,
  ];

  /* Lotie Files */
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      name: "animateBldg",
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/lottie/city.json"),
    });

    // Return clean up function here
    return () => {
      lottie.destroy();
    };
  }, []);

  const navigate = useNavigate();

  return (
    <main>
      <Header />
      <div className="question-wrapper">
        <div className="question-container">
          <h2 className="question-text">{questions[page]}</h2>

          {BodyContent[page]}

          <div
            className={page === 0 ? "arrow_back none" : "arrow_back block"}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          ></div>

          <div className="question-submit-wrapper">
            <button
              className="btn__starter"
              disabled={page === 1 && !ansData.insurance_type}
              onClick={() => {
                if (page === 0) {
                  setPage((currPage) => currPage + 1);
                  setAnsData(answers);
                } else {
                  if (ansData.insurance_type === "Travel Insurance") {
                    /* REDIRECT AFTER SAVING */
                    navigate(`/travel`, { replace: true });
                  } else if (ansData.insurance_type === "Car Insurance") {
                    navigate(`/car`, { replace: true });
                  } else if (ansData.insurance_type === "Home Insurance") {
                    navigate(`/home`, { replace: true });
                  }
                }
              }}
            >
              {page === 0 ? "GET STARTED" : "NEXT"}
            </button>
          </div>
        </div>
      </div>
      <section
        className={
          page === 0 ? "testimonial testimonial-main" : "testimonial none"
        }
      >
        {/* <Carousel autoPlay>
                <div>
                    <h3>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tincidunt vitae semper quis lectus nulla at volutpat. Tempor orci dapibus ultrices in iaculis. Condimentum id venenatis a condimentum vitae sapien pellentesque. Facilisis mauris sit amet massa vitae tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada. Tellus pellentesque eu tincidunt tortor.</h3>
                    <span>- Mary</span>
                    <span></span>
                    
                    <img className="testimonial_img" src={Skwer_logo} alt="test_1" />
                    <p className="">Legend 1</p>
                </div>
                <div>
                    <h3>2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tincidunt vitae semper quis lectus nulla at volutpat. Tempor orci dapibus ultrices in iaculis. Condimentum id venenatis a condimentum vitae sapien pellentesque. Facilisis mauris sit amet massa vitae tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada. Tellus pellentesque eu tincidunt tortor.</h3>
                    <span>- Mary</span>
                    <span></span>

                    <img className="testimonial_img" src={Skwer_logo} alt="test_1" />
                    <p className="">Legend 2</p>
                </div>
                <div>
                    <h3>3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac tincidunt vitae semper quis lectus nulla at volutpat. Tempor orci dapibus ultrices in iaculis. Condimentum id venenatis a condimentum vitae sapien pellentesque. Facilisis mauris sit amet massa vitae tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada. Tellus pellentesque eu tincidunt tortor.</h3>
                    <span>- Mary</span>
                    <span></span>

                    <img className="testimonial_img" src={Skwer_logo} alt="test_1" />
                    <p className="">Legend 3</p>
                </div>
            </Carousel> */}

        {/* <Testimonial /> */}
      </section>

      <div
        className={page === 0 ? "lottie__ani block" : "lottie__ani none"}
        ref={container}
      ></div>
    </main>
  );
}

export default Initial;
