import React, { useEffect } from "react";
import RSA_logo from "../../assets/RSA.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Axios from "axios";

const Output = ({ ansData }) => {
  console.log(ansData);

  const c_ans_2 = ansData.coverage;

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  const customers_note = [
    "Online Purchase",
    "Claim Process",
    "Customer Service",
    "Overall Satisfaction",
  ];

  const companies_data = [
    {
      id: 1,
      company_name: "AIG",
      url: "https://www.aig.ae/individual/travel-insurance",
      c_logo:
        "https://www.aig.ae/content/experience-fragments/aig/emea/uae/en/header/master/_jcr_content/root/responsivegrid/container_1423107018/image.coreimg.png/1602864837987/aig-logo.png",
      region: [
        "Worldwide (excl. US & Canada)rldwide_EX",
        "Worldwide (incl. US & Canada)",
        "United Arab Emirates",
      ],
      t_with: ["Individual", "With Family"],
      how_often: ["Single", "Multiple"],
      insurance_type: [
        "Car Insurance",
        "Travel Insurance",
        "Health Insurance",
        "Home Insurance",
        "Life Insurance",
        "Pet Insurance",
      ],
      coverage: [
        "Medical emergencies or medical evacuations",
        "Covid-19",
        "Baggage Loss or Damage",
        "Trip cancellation, curtailment (cutting short) or delays",
        "Personal Belongings Loss/ theft of and/or damage",
        "Sports Activity Cover",
        "Terrorism Or War Cover",
        "24/7 Worldwide Assistance",
      ],
      ratings: [5, 4, 4, 4],
      price: "100 AED",
    },
    {
      id: 2,
      company_name: "GIG Insurance",
      url: "https://www.giggulf.ae/en/",
      c_logo:
        "https://www.giggulf.ae/image/layout_set_logo?img_id=11683737&t=1668024846571",
      region: [
        "Worldwide (excl. US & Canada)rldwide_EX",
        "Worldwide (incl. US & Canada)",
        "United Arab Emirates",
      ],
      t_with: ["Individual", "With Family"],
      how_often: ["Single", "Multiple"],
      insurance_type: [
        "Car Insurance",
        "Travel Insurance",
        "Health Insurance",
        "Home Insurance",
        "Life Insurance",
        "Pet Insurance",
      ],
      coverage: [
        "Covid-19",
        "Baggage Loss or Damage",
        "Trip cancellation, curtailment (cutting short) or delays",
        "Personal Belongings Loss/ theft of and/or damage",
        "Sports Activity Cover",
        "Terrorism Or War Cover",
        "24/7 Worldwide Assistance",
      ],
      ratings: [4, 3, 3, 4],
      price: "150 AED",
    },
    {
      id: 3,
      company_name: "RSA",
      url: "https://www.rsadirect.ae/",
      c_logo: RSA_logo,
      region: [
        "Worldwide (excl. US & Canada)rldwide_EX",
        "Worldwide (incl. US & Canada)",
        "United Arab Emirates",
      ],
      t_with: ["Individual", "With Family"],
      how_often: ["Single", "Multiple"],
      insurance_type: [
        "Car Insurance",
        "Travel Insurance",
        "Health Insurance",
        "Home Insurance",
        "Life Insurance",
        "Pet Insurance",
      ],
      coverage: [
        "Medical emergencies or medical evacuations",
        "Trip cancellation, curtailment (cutting short) or delays",
        "Personal Belongings Loss/ theft of and/or damage",
        "Sports Activity Cover",
        "Terrorism Or War Cover",
        "24/7 Worldwide Assistance",
      ],
      ratings: [2, 3, 2, 2],
      price: "150 AED",
    },
  ];

  var api__url = "https://miaapi.skwerzone.com";

  /* DATABASE AXIOS */
  const saveData = () => {
    /* http://localhost:3001 https://api.skwergroup.com*/
    Axios.post(`${api__url}/saveDataT`, {
      insurance_type: ansData.insurance_type,
      age: ansData.age,
      region: ansData.region,
      how_often: ansData.how_often,
      t_with: ansData.t_with,
      coverage: ansData.coverage,
      f_name: ansData.f_name,
      l_name: ansData.l_name,
      email: ansData.email,
      mobile: ansData.mobile,
      dateCreated: date,
      timeCreated: time,
    }).then(() => {
      console.log("Travel Ins. Data Saved!");
    });
  };

  /* EXECUTE SAVING */
  useEffect(() => {
    saveData();
  });

  return (
    <>
      <div className="t__output">
        <div className="cov__box">
          {/* ====== REGION DATA-USER INPUT ====== */}
          <div className="cov__item">
            <StarBorderIcon />
            <span>{ansData.region}</span>
          </div>
          {/* ====== TRAVEL WITH DATA-USER INPUT ====== */}
          <div className="cov__item">
            <StarBorderIcon />
            <span>
              {ansData.t_with === "With Family" ? "Family" : "Single"} Travel
              Insurance
            </span>
          </div>
          {/* ====== HOW OFTEN DATA-USER INPUT ====== */}
          <div className="cov__item">
            <StarBorderIcon />
            <span>{ansData.how_often} Trip Travel Insurance</span>
          </div>
          {/* ====== REGION DATA-USER INPUT ====== */}
          {c_ans_2.map((item, index) => {
            return (
              <div className="cov__item">
                <StarBorderIcon />
                <span>{item}</span>
              </div>
            );
          })}
          <h5 className="ratings__div">RATINGS</h5>
          {/* ====== RATINGS CATEGORIES ====== */}
          {customers_note.map((item, index) => {
            return (
              <div className="cov__item">
                <StarBorderIcon />
                <span>{item}</span>
              </div>
            );
          })}
        </div>
        {/* ====== LOOP COMPANIES DATA ====== */}
        {companies_data.map((com__item, c__index) => {
          return (
            <div className="comp__box">
              <div className="comp__item">
                {/* <h2>{com__item.company_name}</h2> */}
                <div className="comp__logo">
                  <img src={com__item.c_logo} alt={com__item.company_name} />
                </div>
                {/* ====== REGION DATA ====== */}
                {com__item.region.includes(ansData.region) ? (
                  <i className="check__icon">
                    <CheckCircleIcon />
                  </i>
                ) : (
                  <i className="x__icon">
                    <CancelIcon />
                  </i>
                )}
                {/* ====== TRAVEL WITH DATA ====== */}
                {com__item.t_with.includes(ansData.t_with) ? (
                  <i className="check__icon">
                    <CheckCircleIcon />
                  </i>
                ) : (
                  <i className="x__icon">
                    <CancelIcon />
                  </i>
                )}
                {/* ====== HOW OFTEN DATA ====== */}
                {com__item.how_often.includes(ansData.how_often) ? (
                  <i className="check__icon">
                    <CheckCircleIcon />
                  </i>
                ) : (
                  <i className="x__icon">
                    <CancelIcon />
                  </i>
                )}
                {/* ====== COVERAGE DATA ====== */}
                {c_ans_2.map((item2, index2) => {
                  return com__item.coverage.includes(item2) ? (
                    <i className="check__icon">
                      <CheckCircleIcon />
                    </i>
                  ) : (
                    <i className="x__icon">
                      <CancelIcon />
                    </i>
                  );
                })}
                {/* ====== RATINGS DATA ====== */}
                <h5 className="ratings__div"> - - - - -</h5>
                {com__item.ratings.map((item2, index2) => {
                  let stars = "";

                  for (let i = 0; i < item2; i++) {
                    stars = stars + "<span class='ratings'></span>";
                  }
                  for (let j = 0; j < 5 - item2; j++) {
                    stars = stars + "<span class='ratings__blank'></span>";
                  }
                  return (
                    <span
                      className="rating__box"
                      dangerouslySetInnerHTML={{ __html: stars }}
                    />
                  );
                })}
              </div>
              <div className="comp__btn__box">
                <a href={com__item.url}>{com__item.company_name}</a>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Output;
